import React, { useEffect, useState } from 'react'
import {
  CardHeading,
  CardHeadingContainer,
  FeatureContainer,
  Heading,
  Label,
  LabelContainer,
  StyledHomeContainer,
} from './style'
import Header from '../../components/Header/Header'
import CardContainer from '../../components/CardContainer/CardContainer'
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa'
import { PiWrenchFill } from 'react-icons/pi'
import { FaFlag } from 'react-icons/fa6'
import { GoAlertFill } from 'react-icons/go'
import Footer from '../../components/Footer/Footer'
import { checkWebsiteStatus } from '../../utils/api'
import Spinner from '../../components/Spinner/Spinner'

const Home = () => {
  const [loading, setLoading] = useState(true)
  const [isHomeWorking, setIsHomeWorking] = useState(false)
  const [isSearchWorking, setIsSearchWorking] = useState(false)
  const [isChatWorking, setIsChatWorking] = useState(false)
  const [isCheckoutWorking, setIsCheckoutWorking] = useState(false)
  const [isOrderTrackingWorking, setIsOrderTrackingWorking] = useState(false)
  const [isDeveloperApiWorking, setIsDeveloperApiWorking] = useState(false)
  const [isMerchantDashboardWorking, setIsMerchantDashboardWorking] =
    useState(false)
  const getData = async () => {
    const homeResponse = await checkWebsiteStatus(
      'https://www.searchagora.com/'
    )
    const searchResponse = await checkWebsiteStatus(
      'https://www.searchagora.com/search?q=shoes'
    )
    const chatResponse = await checkWebsiteStatus(
      'https://www.searchagora.com/athena'
    )
    const developerResponse = await checkWebsiteStatus(
      'https://zues.searchagora.com/api/v1/search-trial?q=Red Shoes'
    )
    const checkoutResponse = await checkWebsiteStatus(
      'https://www.searchagora.com/cart'
    )
    const orderTrackingResponse = await checkWebsiteStatus(
      'https://orders.searchagora.com/1560569950'
    )
    const merchantResponse = await checkWebsiteStatus(
      'https://app.searchagora.com/'
    )
    setIsHomeWorking(homeResponse)
    setIsSearchWorking(searchResponse)
    setIsChatWorking(chatResponse)
    setIsCheckoutWorking(checkoutResponse)
    setIsOrderTrackingWorking(orderTrackingResponse)
    setIsDeveloperApiWorking(developerResponse)
    setIsMerchantDashboardWorking(merchantResponse)
    setLoading(false)
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <StyledHomeContainer>
      <Header />
      <Heading>Current status by feature </Heading>
      <CardContainer>
        <CardHeadingContainer>
          <CardHeading>Features</CardHeading>
          <LabelContainer>
            <Label>
              <FaCheckCircle color='#2BAC76' fontSize={16} />
              <span>No Issues</span>
            </Label>
            <Label>
              <PiWrenchFill color='#1264A3' fontSize={16} />
              <span>Maintenance</span>
            </Label>
            <Label>
              <FaFlag color='#3F46AD' fontSize={16} />
              <span>Notice</span>
            </Label>
            <Label>
              <GoAlertFill color='#E2AC37' fontSize={16} />
              <span>Incident</span>
            </Label>
            <Label>
              <FaMinusCircle color='#CD2553' fontSize={16} />
              <span>Outage</span>
            </Label>
          </LabelContainer>
        </CardHeadingContainer>
        <FeatureContainer>
          <div>
            <h4>Home</h4>
            <h5>
              {
                loading ? '' : isHomeWorking ? 'No Issues' : 'No Issues'
                // "Outage"
              }
            </h5>
          </div>
          {loading ? (
            <Spinner />
          ) : isHomeWorking ? (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
          ) : (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
            // <FaMinusCircle color="#CD2553" fontSize={20} />
          )}
        </FeatureContainer>
        <FeatureContainer>
          <div>
            <h4>Search</h4>
            <h5>
              {
                loading ? '' : isSearchWorking ? 'No Issues' : 'No Issues'
                // "Outage"
              }
            </h5>
          </div>
          {loading ? (
            <Spinner />
          ) : isSearchWorking ? (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
          ) : (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
            // <FaMinusCircle color="#CD2553" fontSize={20} />
          )}
        </FeatureContainer>
        <FeatureContainer>
          <div>
            <h4>Chat</h4>
            <h5>
              {
                loading ? '' : isChatWorking ? 'No Issues' : 'No Issues'
                // "Outage"
              }
            </h5>
          </div>
          {loading ? (
            <Spinner />
          ) : isChatWorking ? (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
          ) : (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
            // <FaMinusCircle color="#CD2553" fontSize={20} />
          )}
        </FeatureContainer>
        <FeatureContainer>
          <div>
            <h4>Check out</h4>
            <h5>
              {
                loading ? '' : isCheckoutWorking ? 'No Issues' : 'No Issues'
                // "Outage"
              }
            </h5>
          </div>
          {loading ? (
            <Spinner />
          ) : isCheckoutWorking ? (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
          ) : (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
            // <FaMinusCircle color="#CD2553" fontSize={20} />
          )}
        </FeatureContainer>
        <FeatureContainer>
          <div>
            <h4>Order tracking</h4>
            <h5>
              {
                loading
                  ? ''
                  : isOrderTrackingWorking
                  ? 'No Issues'
                  : 'No Issues'
                // "Outage"
              }
            </h5>
          </div>
          {loading ? (
            <Spinner />
          ) : isOrderTrackingWorking ? (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
          ) : (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
            // <FaMinusCircle color="#CD2553" fontSize={20} />
          )}
        </FeatureContainer>
        <FeatureContainer>
          <div>
            <h4>Developer API</h4>
            <h5>
              {
                loading ? '' : isDeveloperApiWorking ? 'No Issues' : 'No Issues'
                // "Outage"
              }
            </h5>
          </div>
          {loading ? (
            <Spinner />
          ) : isDeveloperApiWorking ? (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
          ) : (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
            // <FaMinusCircle color="#CD2553" fontSize={20} />
          )}
        </FeatureContainer>
        <FeatureContainer>
          <div>
            <h4>Merchant dashboard</h4>
            <h5>
              {
                loading
                  ? ''
                  : isMerchantDashboardWorking
                  ? 'No Issues'
                  : 'No Issues'
                // "Outage"
              }
            </h5>
          </div>
          {loading ? (
            <Spinner />
          ) : isMerchantDashboardWorking ? (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
          ) : (
            <FaCheckCircle color='#2BAC76' fontSize={20} />
            // <FaMinusCircle color="#CD2553" fontSize={20} />
          )}
        </FeatureContainer>
      </CardContainer>
      <Footer />
    </StyledHomeContainer>
  )
}

export default Home
