import styled from 'styled-components'

export const StyledHomeContainer = styled.div`
  margin: 0 1em;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 768px) {
    height: 100%;
    padding: 1em 0;
  }
`
export const Heading = styled.h2`
  font-size: 30px;
  font-family: sans-serif;
  color: var(--black);
  margin: 1em 0 1em 0;
  text-align: center;
  @media (max-width: 768px) {
    margin: 0.5em 0 0.5em 0;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
`
export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  span {
    font-size: 14px;
    font-weight: 400;
  }
`
export const CardHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 2em;
`
export const CardHeading = styled.h5`
  font-size: 25px;
  font-family: sans-serif;
  color: var(--black);
`
export const FeatureContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--whitesmoke);
  padding: 0.5em;
  h4 {
    font-size: 18px;
    color: var(--black);
    font-weight: 500;
  }
  h5 {
    font-size: 14px;
    font-weight: 400;
    color: var(--darkgray);
  }
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 100vh; /* Full viewport height for demonstration */
  }

  .spinner {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
